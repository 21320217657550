import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./registerServiceWorker";
import VueClipboard from "vue-clipboard2";

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.use(Toast);
Vue.config.productionTip = false;

/* router.beforeEach((to, from, next) => {
  const countdown = new Date("December 1, 2022 15:00:00");
  const now = new Date();
  if (now < countdown && to.name != "comingsoon") next({ name: "comingsoon" });
  else if (now >= countdown && to.name == "comingsoon")
    next({ name: "notFound" });
  else next();
}); */

//authorization verification for each route
//verify access on routing to each page
router.beforeEach((to, from, next) => {
  if (to.meta.auth === "user" && store.getters.getUser === "")
    next({ name: "login" });
  else next();
});

//set the title of each webpage on routing and redirect to the top
router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "VTU Portal";
  window.scroll(0, 0);

  next();
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
