import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { getAnalytics, logEvent } from "firebase/analytics";
import store from "./store";

function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted. " + permission);
    }
  });
}

export default async function firebaseInitialize() {
  const firebaseConfig = {
    apiKey: "AIzaSyDwXhHBxN9WeMBT7zjzrCJm3o1tVKcztVA",
    authDomain: "vtu-silo-e3bfc.firebaseapp.com",
    projectId: "vtu-silo-e3bfc",
    storageBucket: "vtu-silo-e3bfc.appspot.com",
    messagingSenderId: "574387061516",
    appId: "1:574387061516:web:01e2e08bc272c1dda17f29",
    measurementId: "G-R98TTKKDG8",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);
  const analytics = getAnalytics(app);
  logEvent(analytics, "notification_received");

  getToken(messaging, {
    vapidKey:
      "BGWqa0xWNJyBLvydYbMmICrUsXA3dK66KCn2_9kF31OkWGAF_B6XiM5jBcPapKsMWHAc_P5w8x1zMIiZ43W8V5c",
  })
    .then((currentToken) => {
      if (currentToken) {
        store.commit("updateToken", currentToken);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        requestPermission();
      }
    })
    .catch();

  onMessage(messaging, (payload) => {
    var notificationTitle = payload.notification.title;
    var notificationOptions = {
      body: payload.notification.body,
      icon: "./assets/logo60x60.png",
      link: "https://app.vtusilo.com",
    };

    var notification = new Notification(notificationTitle, notificationOptions);
    notification.onclick = function (event) {
      notification.close();
      console.log(event);
    };
  });
}
